import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import { useToken } from '@autobid/nuxt-auth/src/composables/useToken'

export const useCheckUserPermission = () => {
  const { isAuthed } = useAutobidAuth()
  const { tokenData, permissionsMap } = useToken()
  const APP_ID = useRuntimeConfig().public.APP_ID

  /**
   * Check if user has the required permission
   * @param permissionName - name of the permission from the profile
   * @param _appId - if not provided, current app id will be used
   */
  const checkUserPermission = (permissionName: string, _appId?: number) => {
    if (!permissionsMap.value || !tokenData.value) return false

    const appId = _appId ?? APP_ID
    const indexOfPermission =
      permissionsMap.value[appId]?.indexOf(permissionName)

    if (typeof indexOfPermission === 'undefined') return false

    return (
      isAuthed.value &&
      tokenData.value.access.apps[appId][indexOfPermission] === '1'
    )
  }

  return { checkUserPermission }
}
