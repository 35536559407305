import type { User } from '@sentry/vue'
import {
  withScope,
  showReportDialog,
  captureException,
  init,
  setUser
} from '@sentry/vue'
import type { Integration } from '@sentry/integration'
import { httpClientIntegration } from '@sentry/integrations'
import { shouldBeCaptured, isEnabled } from './helper'
import { IGNORE_ERRORS } from './consts/IGNORE_ERRORS'

export default defineNuxtPlugin(async (nuxtApp) => {
  if (!isEnabled(nuxtApp.$config.public)) {
    return
  }

  const {
    VERSION,
    SELF_URL,
    ui: { theme },
    SENTRY: { ENVIRONMENT: environment, DSN: dsn, REPLAYS, REPORT_DIALOG }
  } = nuxtApp.$config.public

  const areReplaysEnabled = Number(REPLAYS) === 1

  const integrations: Integration[] = [httpClientIntegration()]

  if (Number(REPORT_DIALOG) === 1) {
    const { reportingObserverIntegration } = await import(
      '@sentry/integrations'
    )
    const { feedbackIntegration } = await import('@sentry/vue')

    integrations.push(
      reportingObserverIntegration(),
      feedbackIntegration({
        showBranding: false,
        colorScheme: 'system',
        submitButtonLabel: 'Submit',
        messagePlaceholder: 'Please describe the issue',
        isRequiredLabel: '(*)'
      })
    )
  }

  if (areReplaysEnabled) {
    const { replayIntegration } = await import('@sentry/vue')

    integrations.push(
      replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      })
    )
  }

  init({
    app: nuxtApp.vueApp,
    autoSessionTracking: true,
    dsn,
    release: `${theme}@${VERSION}`,
    environment,
    denyUrls: [
      // Chrome extensions
      /extension\//i,
      /^chrome:\/\//i,
      /cdn\.cookielaw\.org/
    ],
    integrations,
    trackComponents: true,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    tracePropagationTargets: [SELF_URL as string],
    sampleRate: 1,
    tracesSampleRate: 1,
    ...(areReplaysEnabled
      ? { replaysSessionSampleRate: 0.1, replaysOnErrorSampleRate: 1 }
      : {}),
    ignoreErrors: IGNORE_ERRORS,
    beforeSend: (event) => {
      if (event.exception && Number(REPORT_DIALOG) === 1) {
        showReportDialog({ eventId: event.event_id })
      }

      return event
    }
  })

  nuxtApp.vueApp.config.errorHandler = (err, context) => {
    withScope((scope) => {
      if (!shouldBeCaptured(err, nuxtApp.$config.public)) return

      scope.setExtra('context', context)
      captureException(err)
    })
  }

  nuxtApp.hook('app:error', (error) => {
    if (!shouldBeCaptured(error, nuxtApp.$config.public)) return

    captureException(error)
  })

  return {
    provide: {
      sentrySetUser: (user: User) => setUser(user)
    }
  }
})
