<template>
  <div
    class="w-full flex-1 self-start lg:sticky lg:top-[calc(var(--desktop-nav-height)+15px)] lg:mr-3"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
provide('isSidebar', true)
</script>
