<!-- eslint-disable vue/no-v-html -->
<template>
  <HeadlessSwitch
    class="checkbox flex h-full w-fit items-center"
    :class="small ? 'gap-2' : 'gap-4'"
    :model-value="checked"
    :disabled="isDisabled"
    @update:model-value="handleInput"
  >
    <div
      class="checkbox-inner flex items-center justify-center rounded-md border-2 border-primary transition hover:scale-110"
      :class="{
        'checked bg-primary': checked,
        'bg-white': !checked && !isDisabled,
        'checkbox-small': small,
        'checkbox-big': !small,
        'checkbox-disabled bg-[#C1C1C1]': isDisabled
      }"
    >
      <HeadlessTransitionRoot
        enter="transition-opacity duration-75"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity duration-150"
        leave-from="opacity-100"
        leave-to="opacity-0"
        :show="checked"
        appear
      >
        <Icon mode="svg" name="material-symbols:check" class="text-white" />
      </HeadlessTransitionRoot>
    </div>
    <CheckboxLabel
      class="text-ellipsis"
      :class="{
        'flex justify-between': labelSuffix !== undefined,
        '!text-[#AFAFAF]': isDisabled
      }"
      v-bind="labelProps"
    >
      <span
        class="[&>a]:underline"
        @click="handleLabelClick"
        v-html="label"
      ></span>
      <span v-if="labelSuffix !== undefined" class="pl-1">
        {{ labelSuffix }}
      </span>
    </CheckboxLabel>
  </HeadlessSwitch>
</template>

<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import CheckboxLabel from './CheckboxLabel.vue'

interface Props {
  checked: boolean
  label: string
  labelSuffix?: string | number
  small?: boolean
  disabled?: boolean
  labelProps?: HTMLAttributes
  uncheckIfDisabled?: boolean
}

const props = defineProps<Props>()

const emits = defineEmits<{ (e: 'change', value: boolean): void }>()

const isDisabled = computed(() => props.disabled)

const handleInput = () => {
  emits('change', !props.checked)
}

const handleLabelClick = (e) => {
  if (e.target.tagName.toLowerCase() !== 'a') return

  setTimeout(() => {
    handleInput()
  }, 100)
}

const handleUncheckIfDisabled = () => {
  if (props.uncheckIfDisabled && isDisabled.value && props.checked) {
    emits('change', false)
  }
}

handleUncheckIfDisabled()
watch(isDisabled, () => {
  handleUncheckIfDisabled()
})
</script>

<style lang="scss" scoped>
// threre is already a asterisk plugin for formkit but this component is an exception, because it uses a custom label that is required to render custom html (e.g. a link to the terms of service)
.checkbox[aria-required='true'] .checkbox-label {
  &::after {
    content: '*';
    color: var(--color-red);
    margin-left: 2px;
  }
}
</style>
