import { SLUGS_BLACKLIST } from '@autobid/strapi-integration/constants/SLUGS_BLACKLIST'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import { useQueryClient } from '@tanstack/vue-query'
import type { StrapiPage } from '@autobid/strapi-integration/typescript/strapi/Page'
import { extractStrapiComponents } from '@autobid/strapi-integration/utils/content'
import type { Redirection } from '@autobid/strapi-integration/utils/redirectionHelper'
import { useStrapiLang } from '@autobid/strapi-integration/composable/useStrapiLang'
import { langsNames } from '@autobid/ui/utils/langUtils'

export interface PageProps {
  slug: string
  draft?: boolean
  draftCollection?: string
  // allow to display the slot even if the pageData is empty
  allowEmpty?: boolean
  ignoreBlacklist?: boolean
}

type Resp = {
  data: StrapiPage[]
  redirection?: Redirection
}

const addDefaultOgSeoData = (queryPage: StrapiPage) => {
  if (!queryPage.attributes.seo) return

  const hasOgSeoData = queryPage.attributes.seo.metaSocial.find(
    (el) => el.socialNetwork === 'Facebook'
  )

  // add og data if it's not exist
  if (!hasOgSeoData && queryPage.attributes.seo) {
    queryPage.attributes.seo.metaSocial.push({
      id: 0,
      socialNetwork: 'Facebook',
      title: queryPage.attributes.seo.metaTitle,
      description: queryPage.attributes.seo.metaDescription,
      image: queryPage.attributes.seo.metaImage
    })
  }
}

const getSortedLocales = (queryPage: StrapiPage) => {
  return [...(queryPage.attributes.localizations?.data ?? [])].sort((a, b) => {
    const aLang = langsNames[a.attributes.locale].trim()
    const bLang = langsNames[b.attributes.locale].trim()

    return aLang.localeCompare(bLang, 'en', { sensitivity: 'base' })
  })
}

export const usePage = () => {
  const { $customFetch } = useCustomFetch()
  const { strapiLang: locale } = useStrapiLang()
  const route = useRoute()
  const urlParams = route.href?.split('?')?.[1] ?? ''
  const queryClient = useQueryClient()

  const getPathName = (slug: string) => {
    return slug.startsWith('/') ? slug : `/${locale.value}/${slug}`
  }

  const getPageQueryKey = (props: PageProps) => [
    'page',
    props,
    locale.value,
    getPathName(props.slug)
  ]

  const fetchPage = async (props: PageProps) => {
    const lastPartOfSlug = props.slug.split('/').reverse()[0]

    if (!props.ignoreBlacklist && SLUGS_BLACKLIST.includes(lastPartOfSlug)) {
      return null
    }

    const response = await $customFetch<Resp>('/api/page', {
      method: 'POST',
      body: {
        path: getPathName(props.slug),
        locale: locale.value,
        ...(props.draft && {
          draft: true,
          draftCollection: props.draftCollection
        }),
        ...(urlParams.length && { params: urlParams })
      }
    })

    if (!response) {
      return null
    }

    if (response.redirection) {
      return {
        redirection: response.redirection
      }
    }

    const pageData = response.data?.[0]
    if (!pageData) return null

    addDefaultOgSeoData(pageData)

    if (pageData.attributes.localizations?.data?.length) {
      pageData.attributes.localizations.data = getSortedLocales(pageData)
    }

    return {
      page: pageData,
      components: extractStrapiComponents(pageData.attributes.contentSections),
      headerSections: extractStrapiComponents(
        pageData.attributes.headerSections
      ),
      sidebarSections: extractStrapiComponents(
        pageData.attributes.sidebarSections
      )
    }
  }

  const prefetchPage = async (props: PageProps) => {
    const queryKey = getPageQueryKey(props)

    const isAlreadyPrefetched = queryClient.getQueryData(queryKey) !== undefined

    if (isAlreadyPrefetched) return

    const data = await fetchPage(props)
    queryClient.setQueryData(getPageQueryKey(props), data)
  }

  return {
    getPageQueryKey,
    fetchPage,
    prefetchPage
  }
}
