import type { Route } from 'typescript/Route'
import { computed } from 'vue'

type RedirectablePage = {
  path?: string
  query?: Record<string, string>
  redirectable: boolean
}

type RedirectablePages = {
  prev?: RedirectablePage
  current: RedirectablePage
}

type AuthData = {
  authorizing: 'login' | 'logout' | null
  redirect?: RedirectablePages
  runCallback: 0 | 1
}

/**
 * @param {Route} _route - `required` when calling in middleware
 */
export const useAutobidAuth = (_route?: Route) => {
  const {
    signOut: sidebaseSignOut,
    signIn: sidebaseSignIn,
    status,
    data: sessionData
  } = useAuth()
  const route = _route || useRoute()
  const cookie = useCookie('auth-state', { maxAge: 60 * 60, watch: true })
  const {
    SELF_URL,
    MY_AUTOBID_URL,
    autobidAuth: { authority: url, clientId }
  } = useRuntimeConfig().public
  const authDisabled = inject('authDisabled', false)

  const authData = computed(() => (cookie.value ?? {}) as AuthData)

  const isAuthed = computed(() => {
    if (authDisabled) return false

    return status.value === 'authenticated'
  })

  const updateCookie = (data: Partial<AuthData>) => {
    cookie.value = {
      ...(cookie.value ?? {}),
      ...data
    }
  }

  const setPageRedirectData = (routeData: RedirectablePage) => {
    if (authData.value.authorizing && process.server) return

    const newRouteData = {
      ...routeData,
      ...(routeData.path
        ? {}
        : {
            path: route.path,
            query: route.query
          })
    }

    let redirectablePages = authData.value.redirect

    if (!redirectablePages) {
      redirectablePages = {
        current: newRouteData
      }
    } else if (redirectablePages.current.path === newRouteData.path) {
      redirectablePages.current = {
        ...newRouteData,
        redirectable: redirectablePages.current.redirectable
          ? newRouteData.redirectable
          : false
      }
    } else {
      if (redirectablePages.current.redirectable) {
        redirectablePages.prev = { ...redirectablePages.current }
      }

      redirectablePages.current = newRouteData
    }

    updateCookie({
      redirect: redirectablePages
    })
  }

  const getRouteDataToRedirectTo = (locale: string) => {
    if (authData.value.redirect?.current.redirectable)
      return authData.value.redirect.current

    if (authData.value.redirect?.prev?.redirectable)
      return authData.value.redirect.prev

    return {
      path: `/${locale}`,
      query: {}
    }
  }

  const signIn = async () => {
    updateCookie({
      authorizing: 'login',
      runCallback: 1
    })

    await sidebaseSignIn('autobid', { callbackUrl: route.fullPath })
  }

  const signOutFromMyAutobid = (logoutInSameWindow: boolean) => {
    if (!MY_AUTOBID_URL) return

    const MY_AUTOBID_LOGOUT_TIME = 1000
    const myAutobidIframe = document.createElement('iframe')
    myAutobidIframe.setAttribute(
      'src',
      `${MY_AUTOBID_URL}/?action=auth&show=logout`
    )
    myAutobidIframe.setAttribute(
      'class',
      'absolute left-0 top-0 opacity-0 z-0 invisible'
    )
    document.body.appendChild(myAutobidIframe)

    if (logoutInSameWindow) {
      setTimeout(() => {
        myAutobidIframe.remove()
      }, 2000)
    }

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true)
      }, MY_AUTOBID_LOGOUT_TIME)
    })
  }

  const signOut = async (inSameWindow?: boolean) => {
    updateCookie({
      authorizing: 'logout',
      runCallback: 1
    })

    await Promise.all([
      signOutFromMyAutobid(!!inSameWindow),
      /*
        https://next-auth.js.org/configuration/callbacks#redirect-callback
        external redirects are not allowed by default, therefore  redirect is set to false to avoid writing 
        the redirect callback, the redirection takes place at the end of this function
      */
      sidebaseSignOut({ redirect: false })
    ])

    if (!inSameWindow) {
      window.location.href = `${url}session/end?client_id=${clientId}&post_logout_redirect_uri=${SELF_URL}`
    }
  }

  const afterAuthCallback = async (locale: string) => {
    const shouldBeCalled =
      authData.value.authorizing && authData.value.runCallback

    if (process.client) {
      updateCookie({
        runCallback: 0,
        ...(shouldBeCalled ? {} : { authorizing: null })
      })
    }

    if (!shouldBeCalled) return

    const routeToNavigateTo = getRouteDataToRedirectTo(locale)
    if (
      process.server &&
      routeToNavigateTo &&
      !routeToNavigateTo.path?.includes('http') &&
      routeToNavigateTo.path !== route.path
    ) {
      await navigateTo(routeToNavigateTo)
    }
  }

  return {
    authData,
    sessionData,
    isAuthed,
    setPageRedirectData,
    updateCookie,
    signIn,
    signOut,
    afterAuthCallback
  }
}
