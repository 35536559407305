import type { AuctionItemListType } from '@autobid/strapi-integration/typescript/strapi/dynamic/DynamicAuctionItemList'
import type {
  AuctionCarsVariables,
  SortBy,
  SortByItem
} from '@autobid/ui/composables/useFetchCars'
import type { Filters } from '@autobid/nuxt-pinia-store/types/Cars'
import { STAGE_VALUES } from '@autobid/nuxt-pinia-store/consts/stageValues'

type Props = {
  filters: Filters
  sortingType: string
  carIds?: number[]
  listType?: AuctionItemListType
  auctionId: number
  pageSize: number
  appId: number
  currentPage?: number
  lang: string
}

const setRange = (
  _from?: Array<string | number>,
  _to?: Array<string | number>
) => {
  const from = _from?.[0]
  const to = _to?.[0]

  if (!from && !to) return undefined

  return {
    valueFrom: from ? +from : null,
    valueTo: to ? +to : null
  }
}

const getSingleNumber = (value?: Array<string | number>) => {
  return value?.[0] ? Number(value[0]) : undefined
}

export const getSingleFilterValue = (value: unknown | unknown[]) => {
  return Array.isArray(value)
    ? value[0]
    : String(value).includes(',')
    ? String(value).split(',')[0]
    : value
}

const getSortBy = (
  name: SortByItem['name'],
  order: SortByItem['order']
): SortBy => {
  if (name === 'catalogNumber') {
    return [
      {
        name,
        order
      },
      { name: 'auctionStartDate', order: 'ASCENDING' },
      { name: 'auctionNumber', order: 'ASCENDING' }
    ]
  }

  if (name === 'auctionStartDate') {
    return [
      {
        name,
        order
      },
      { name: 'catalogNumber', order: 'ASCENDING' },
      { name: 'auctionNumber', order: 'ASCENDING' }
    ]
  }

  return [
    {
      name,
      order
    },
    { name: 'auctionStartDate', order: 'ASCENDING' },
    { name: 'catalogNumber', order: 'ASCENDING' }
  ]
}

export const getFilters = ({
  filters,
  sortingType,
  carIds,
  listType,
  auctionId,
  pageSize,
  appId,
  currentPage,
  lang
}: Props) => {
  const filterValues: Partial<Record<keyof Filters, Array<string | number>>> =
    {}
  const [name, order] = getSingleFilterValue(sortingType).split('-') as [
    SortByItem['name'],
    SortByItem['order']
  ]

  for (const key in filters) {
    filterValues[key as keyof Filters] = Object.keys(
      filters[key as keyof typeof filters]
    )
  }

  const result: AuctionCarsVariables = {
    ids: carIds,
    appIds: listType === 'carList' ? [appId] : undefined,
    pageNumber: getSingleFilterValue(currentPage ?? 1) - 1,
    includeNotVisibleInList: listType !== 'carSearch',
    publicationStatus: ['PUBLISHED'],
    stages:
      listType === 'carSearch'
        ? STAGE_VALUES.online
        : STAGE_VALUES[filterValues.stage?.[0] as keyof typeof STAGE_VALUES],
    typeCategory: filterValues.category as string[],
    manufacturerModel: filterValues.manufacturers as string[],
    e223: filterValues.e223?.map(Number),
    /*
      todo: delete id later (ev filter) - the ev field is removed from the application but for testing purpose 
      it should be possible to filter by this parameter, so after tests it should be removed
    */
    ev: filterValues.ev?.[0] ? true : undefined,
    e174: filterValues.e174?.[0] ? true : undefined,
    auctionIds: auctionId ? [auctionId] : undefined,
    pageSize,
    rankingListType: 'BAROMETER',
    supplierIds: filterValues.supplierIds?.map(Number),
    e17: filterValues.e17?.map(Number),
    e21: setRange(filterValues.e21from, filterValues.e21to),
    e68: setRange(filterValues.e68from, filterValues.e68to),
    e19: setRange(filterValues.e19from, filterValues.e19to),
    e24: getSingleNumber(filterValues.e24),
    earliestDateOfAddition:
      (filterValues.earliestDateOfAddition?.[0] as string) ?? undefined,
    e70: getSingleNumber(filterValues.e70),
    e31: filterValues.e31?.map(Number),
    sortBy: getSortBy(name, order),
    lang
  }

  return result
}
