import type { UserProfile } from '@autobid/nuxt-pinia-store/types/User'
import { useQuery } from '@tanstack/vue-query'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import { computed } from 'vue'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'

type QueryKeyProps = {
  isAuthed: boolean
  userId: number
}
export const getProfileQueryKey = ({ isAuthed, userId }: QueryKeyProps) => {
  return ['profile', isAuthed, userId]
}

export const useProfile = () => {
  const { locale } = useI18n()
  const { isAuthed, sessionData } = useAutobidAuth()
  const headers = useRequestHeaders(['cookie'])
  const { $customFetch } = useCustomFetch()

  const queryKey = computed(() =>
    getProfileQueryKey({
      isAuthed: isAuthed.value,
      userId: sessionData.value?.user?.id
    })
  )

  const queryFn = async () => {
    if (!isAuthed.value) return null

    return (await $customFetch('/api/profile', {
      method: 'GET',
      headers: {
        'Accept-language': locale.value,
        ...headers
      }
    })) as UserProfile
  }

  const revalidate = async () => {
    await $customFetch('/api/profile-revalidate')
  }

  return {
    ...useQuery({
      queryKey: queryKey.value,
      queryFn
    }),
    revalidate
  }
}
