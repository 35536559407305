<template>
  <div
    v-if="browser && !isSupported && (!isAccepted || isError)"
    class="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-black/30 p-3"
  >
    <div
      class="relative z-10 w-full max-w-md overflow-hidden bg-white/100 p-6 text-left align-middle shadow-xl"
    >
      <Icon
        v-if="!isError"
        name="mdi:close"
        mode="svg"
        size="24"
        class="absolute right-4 top-4 z-50"
        role="button"
        @click="closeModal"
      />
      <header
        class="flex -translate-y-2.5 items-center gap-2 text-xl font-medium leading-6 text-gray-900"
      >
        <Icon
          name="mdi:alert-circle"
          mode="svg"
          size="32"
          class="text-red-500"
        />
        <h3>{{ $t('not-supported-browser.title') }}</h3>
      </header>

      <div class="mt-2 max-h-[calc(100vh-140px)] overflow-y-auto">
        <p class="text-gray-500">{{ $t(ERROR_MESSAGE[browser]) }}</p>
        <p class="mt-2 text-gray-500">
          {{ $t('not-supported-browser.description') }}
        </p>

        <div class="mt-4 flex w-full justify-between">
          <a
            :href="LINK[browser]"
            rel="noopener noreferrer"
            target="_blank"
            class="mt-4 inline-flex items-center justify-center gap-2 border border-transparent px-4 py-2 text-sm font-medium text-white"
            :class="COLOR[browser]"
          >
            <Icon :name="ICON[browser]" mode="svg" size="32" />
            <template v-if="browser !== 'unknown'">
              {{ $t('not-supported-browser.update-browser') }}
            </template>
            <template v-else>
              {{ $t('not-supported-browser.download-chrome') }}
            </template>
          </a>
          <button
            type="button"
            class="mt-4 inline-flex items-center justify-center gap-2 border border-transparent bg-orange-400 px-4 py-2 text-sm font-medium text-white disabled:cursor-not-allowed disabled:bg-slate-500 disabled:text-white disabled:opacity-40"
            :disabled="isError"
            @click="closeModal"
          >
            {{ $t('not-supported-browser.continue-anyway') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { checkIsSupported } from '@autobid/browser-support/src'

interface Props {
  isError?: boolean
}

const props = defineProps<Props>()

function closeModal() {
  if (props.isError) {
    return
  }

  isAccepted.value = 1
}

const getUserBrowser = (userAgent: string) => {
  const userAgentLowerCase = userAgent.toLowerCase()
  if (userAgentLowerCase.includes('edg')) {
    return 'edge'
  }
  if (userAgentLowerCase.includes('opr')) {
    return 'opera'
  }
  if (userAgentLowerCase.includes('trident')) {
    return 'ie'
  }
  if (
    userAgentLowerCase.includes('safari') &&
    !userAgentLowerCase.includes('chrome')
  ) {
    return 'safari'
  }
  if (userAgentLowerCase.includes('firefox')) {
    return 'firefox'
  }
  if (userAgentLowerCase.includes('chrome')) {
    return 'chrome'
  }

  return 'unknown'
}

const headers = useRequestHeaders(['user-agent'])

const userAgent = computed(() => {
  if (process.server) {
    return headers['user-agent'] || ''
  }

  if (navigator) return navigator.userAgent || ''

  return ''
})

const browser = getUserBrowser(userAgent.value)
const isSupported = checkIsSupported(userAgent.value)
const isAccepted = useCookie('browserSupport', { maxAge: 60 * 60 * 24 * 365 })
const ICON = {
  chrome: 'mdi:google-chrome',
  safari: 'mdi:apple-safari',
  firefox: 'mdi:firefox',
  ie: 'mdi:internet-explorer',
  edge: 'mdi:microsoft-edge',
  opera: 'mdi:opera',
  unknown: 'mdi:google-chrome'
}

const COLOR = {
  chrome: 'bg-blue-600 hover:bg-blue-700',
  safari: 'bg-blue-600 hover:bg-blue-700',
  firefox: 'bg-orange-600 hover:bg-orange-700',
  ie: 'bg-blue-600 hover:bg-blue-700',
  edge: 'bg-blue-600 hover:bg-blue-700',
  opera: 'bg-red hover:bg-red-700',
  unknown: 'bg-blue-600 hover:bg-blue-700'
}

const ERROR_MESSAGE = {
  unknown: 'not-supported-browser.unknown',
  chrome: 'not-supported-browser.not-supported-version',
  safari: 'not-supported-browser.not-supported-version',
  firefox: 'not-supported-browser.not-supported-version',
  ie: 'not-supported-browser.not-supported-version',
  edge: 'not-supported-browser.not-supported-version',
  opera: 'not-supported-browser.not-supported-version'
}

const LINK = {
  chrome: 'https://www.google.com/chrome/',
  unknown: 'https://www.google.com/chrome/',
  safari: 'https://support.apple.com/downloads/safari',
  firefox: 'https://www.mozilla.org/firefox/new',
  ie: 'https://www.microsoft.com/edge',
  edge: 'https://www.microsoft.com/edge',
  opera: 'https://www.opera.com/'
}
</script>
